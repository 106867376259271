<template>
    <div class="content">
        <div class="header_pic">
            <img :src="info.top_img" alt="">
        </div>
        <div class="card1">
            <div class="row_price">
                <div class="price_left">
                    <div class="price_left_top">
                        <span style="font-size:18px;color:#f20800;vertical-align:bottom;">￥</span>
                        <span
                            style="font-size:36px;color:#f20800;margin-right:4px;vertical-align:middle;">{{ info.one_price }}</span>
                        <span style="font-size:12px;color:#bbbbbb;vertical-align:bottom;">优惠价</span>
                        <span style="font-size:12px;color:#bbbbbb;vertical-align:bottom;">￥{{ info.old_price }}</span>
                    </div>
                    <div class="price_left_down">

                        <span> <img src="~@/assets/images/distributionIndex/fire.png"
                                alt="">热卖{{ info.xiaoliang }}件</span>
                    </div>
                </div>
                <div class="price_right">
                    <div class="price_right_center"></div>
                </div>
            </div>

            <div class="title">
                {{ info.title }}
            </div>

            <div class="recommend">
                <div class="recommend_center">
                    推荐理由：{{ info.desc }}
                </div>

            </div>
        </div>
        <div class="card2">
            <div class="row1">
                <div style="margin-right:20px;height:45px;">已选</div>
                <div style="width:80%;border-bottom:1px solid #f5f5f5;height:45px;">流量卡,1件</div>
            </div>
            <div class="row3">
                <div style="margin-right:20px;height:45px;">送至</div>
                <div style="width:80%;height:45px;">
                    <div class="top">
                        <img src="~@/assets/images/bigFlow/gps.png" alt="">
                        <span>{{ form.province + form.city + form.area + form.addressInfo }}</span>
                    </div>
                    <div class="bottom"><span style="color:#eb1007;">现货</span><span
                            style="color: #a2a2a2;font-weight:400;">,预计送达({{ nextTime }})</span></div>
                </div>
            </div>
            <div class="row2">
                <div class="num">
                    <img src="~@/assets/images/distributionIndex/gou1.png" alt="">
                    <span>在线支付</span>
                </div>
                <div class="num">
                    <img src="~@/assets/images/distributionIndex/gou1.png" alt="">
                    <span>7天无理由退货</span>
                </div>
                <div class="num">
                    <img src="~@/assets/images/distributionIndex/gou1.png" alt="">
                    <span>全国包邮（部分地区除外）</span>
                </div>
            </div>

        </div>



        <div class="card3">
            <div class="header">
                <div class="name">商品详细</div>
                <div class="red"></div>
            </div>
            <div v-html="info.content">

            </div>
        </div>


        <div class="btn">
            <div class="price" style="vertical-align:bottom;">
                <div class="">
                    ￥
                </div>
                <div class=""
                    style="font-size: 25px;color: red;vertical-align:bottom;transform: translateY(2px);margin-right: 5px;">
                    {{ info.one_price }}
                </div>
                <div class="" style="font-size: 12px;color: #bfbfbf;vertical-align:bottom;">
                    优惠价 <span style="text-decoration: line-through;">￥{{ info.old_price }}</span>
                </div>
            </div>
            <div class="btn-center">
                <div v-if="info.one_price != 0" @click="show = true">立即购买</div>
                <div v-else @click="show = true">0元抢购</div>
            </div>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '80%' }">
            <div class="popBox">
                <div class="inp">
                    <van-cell-group>
                        <van-field v-model="form.name" label="姓名" placeholder="请输入真实姓名" />
                    </van-cell-group>
                </div>
                <div class="inp">
                    <van-cell-group>
                        <van-field v-model="form.phone" label="电话" type="tel" maxlength="11" placeholder="请输入联系电话" />
                    </van-cell-group>
                </div>
                <div class="inp">
                    <van-cell-group>
                        <van-field v-model="form.cardId" label="验证码" type="tel" maxlength="6" placeholder="请填写手机验证码">
                            <template #button>
                                <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
                                <div style="color:#f1462e;padding:0 20px;" @click="getCode()" v-if="!disabled">
                                    {{ codeText }}</div>
                                <div style="color:#f1462e;padding:0 20px;" v-if="disabled">{{ codeText }}</div>
                            </template>
                        </van-field>
                    </van-cell-group>
                </div>
                <div class="area">
                    <div class="area-a">地址</div>
                    <div style="width:100%;height: 44px;line-height:44px;">
                        <!-- <city @sendCity="sendCityFn" :address="form.address" :region='regionAddress'></city> -->
                        <div @click="picker = true">{{ form.province + ' ' + form.city + ' ' + form.area }}</div>
                        <!-- <div @click="picker = true">{{}}</div> -->
                    </div>
                </div>

                <div class="inp">
                    <van-cell-group>
                        <van-field v-model="form.addressInfo" placeholder="请输入详细地址" />
                    </van-cell-group>
                </div>

                <div class="payType">
                    <div class="item" @click="selectPay(1)">
                        <div class="left">
                            <img src="~@/assets/images/bigFlow/wxpay.png" alt="">
                            <span>微信支付</span>
                        </div>
                        <div class="right">
                            <img src="~@/assets/images/bigFlow/noActive.png" alt="" v-if="payType == 2">
                            <img src="~@/assets/images/bigFlow/active.png" alt="" v-if="payType == 1">
                        </div>
                    </div>
                    <div class="item" @click="selectPay(2)" v-if="!wx">
                        <div class="left">
                            <img src="~@/assets/images/bigFlow/alipay.png" alt="">
                            <span>支付宝支付</span>
                        </div>
                        <div class="right">
                            <img src="~@/assets/images/bigFlow/noActive.png" alt="" v-if="payType == 1">
                            <img src="~@/assets/images/bigFlow/active.png" alt="" v-if="payType == 2">
                        </div>
                    </div>
                </div>
                <div class="important">*请填写真实身份信息,避免收获地址错误或无法电话联系*</div>
                <div class="footbtn">
                    <van-button :disabled="disabled2" type="primary" round size="large" color="#fd6550"
                        @click="onbuyCard()">立即购买</van-button>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="picker" position="bottom" :style="{ height: '50%' }">
            <van-area @confirm="confirm" @cancel="cancel()" title="标题" :area-list="areaList" :value="areainfo" />
        </van-popup>
    </div>
</template>
<script>
import {
    getCardDetail,
    buyCard,
    sendVerifyCode,
    getOrderId,
    getBuyUrlScheme
} from "@/untils/distubritionIndex.js";
import city from './components/city.vue'
import isweixin from "@/untils/isweixin.js";
import { areaList } from '@vant/area-data';
const jsonp = require('jsonp');
var wx = require('weixin-js-sdk') || window['wx'];
export default {
    components: {
        city
    },
    data() {
        return {
            info: {},
            show: false,
            picker: false,
            areaList,
            areainfo: '',
            form: {
                name: '',
                phone: '',
                cardId: '',
                address: '',
                addressInfo: "",
                province: '',
                city: '',
                area: ''
            },
            regionAddress: '',
            payType: 1,
            result: {},
            code: '',
            p_openid: '',
            sp: '',
            from: 1,
            openid: '',
            provinceList: {},
            cityList: {},
            infoList: {},
            times: null,
            timer: null,
            nextTime: '',
            codeText: '获取验证码',
            disabled: false,
            disabled2: false,
            invite_phone: '',
            wx: true, //微信环境
        }
    },
    beforeDestroy() {
        clearTimeout(this.times);
    },
    created() {
        if (isweixin()) {

            let local = window.location.href;
            if (!this.$route.query.openid) {
                window.location.href = 'https://connect.yuna6.com/getBuyUrl?url=' + encodeURIComponent(local)
            } else {
                var search = window.location.search;
                var openid = this.getSearchString('openid', search);
                this.p_openid = this.getSearchString('p_openid', search);
                this.sp = this.getSearchString('sp', search);
                this.from = this.getSearchString('from', search);

                if (this.getSearchString('invite_phone', search)) {
                    this.invite_phone = this.getSearchString('invite_phone', search);
                } else {
                    this.invite_phone = ''
                }





                window.sessionStorage.setItem('openid', openid)
            }
        } else {
            this.wx = false
            // window.location.href = "http://localhost:8081/bigFlowPage?from=2&sp=8e296a067a37563370ded05f5a3bf3ec&p_openid=&invite_phone=17633596180"
            var search = window.location.search;
            // var openid = this.getSearchString('openid', search);
            // this.p_openid = this.getSearchString('p_openid', search);
            this.sp = this.getSearchString('sp', search);
            this.from = this.getSearchString('from', search);
            this.invite_phone = this.getSearchString('invite_phone', search);
            window.sessionStorage.setItem('openid', '')
        }
    },
    mounted() {
        this.getnext()
        this.getInfo()

    },
    methods: {
        // 获取验证码
        getCode() {
            if (this.form.phone.trim().length != 11) {
                return this.$toast('请输入完整的手机号')
            }
            this.disabled = true
            this.codeText = 60
            let data = {
                phone: this.form.phone
            }

            sendVerifyCode({ data }).then(res => {
                console.log(res);
                if (res.data.code == 1) {
                    this.$toast(res.data.msg)
                }
            })
            let timer = setInterval(() => {
                this.codeText--
                if (this.codeText == 0) {
                    this.codeText = "获取验证码"
                    clearInterval(timer)
                    this.disabled = false
                }
            }, 1000)



        },

        getnext() {

            var myDate = new Date();
            myDate.getHours();  //获取当前小时数(0-23)
            console.log(myDate.getHours());
            if (myDate.getHours() < 16) {
                var day3 = new Date();
                day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000 * 2);
                var s3 = (day3.getMonth() + 1) + "月" + day3.getDate() + '日'
            }

            if (myDate.getHours() >= 16) {
                var day3 = new Date();
                day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000 * 3);
                var s3 = (day3.getMonth() + 1) + "月" + day3.getDate() + '日'
            }
            this.nextTime = s3

        },


        // 确定
        confirm(value) {
            // console.log(value);
            this.form.province = value[0].name
            this.form.city = value[1].name
            this.form.area = value[2].name
            this.form.address = this.form.province + ' ' + this.form.city + ' ' + this.form.area
            this.picker = false
        },
        cancel() {
            this.picker = false
        },


        getPCA() {
            this.provinceList = this.areaList.province_list
            this.cityList = this.areaList.city_list
            this.infoList = this.areaList.county_list
            const obj = this.provinceList
            const obj2 = this.cityList
            const obj3 = this.infoList
            for (let i in obj) {
                if (obj[i].includes(this.result.Province)) {
                    this.form.province = obj[i]
                }
            }
            for (let i in obj2) {
                if (obj2[i].includes(this.result.City)) {
                    this.areainfo = i
                    this.form.city = obj2[i]
                }
            }

            if (this.result.District != '') {
                for (let i in obj3) {

                    if (obj3[i].includes(this.result.District)) {
                        this.areainfo = i
                        this.form.area = obj3[i]
                    }
                }
            } else {

                let areaArr = []
                for (let i in obj3) {

                    if (i.includes(this.areainfo.slice(0, 4))) {

                        // console.log(i);
                        // console.log(obj3[i]);
                        areaArr.push({ val: i, name: obj3[i] })
                    }

                }

                this.areainfo = areaArr[0].val
                this.form.area = areaArr[0].name
            }

        },

        getSearchString(key, Url) {
            var str = Url;
            str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
            // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
            var arr = str.split("&");
            var obj = new Object();
            // 将每一个数组元素以=分隔并赋给obj对象
            for (var i = 0; i < arr.length; i++) {
                var tmp_arr = arr[i].split("=");
                obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
            }
            return obj[key];
        },
        sendCityFn(data) {
            // console.log(data);
            let arr = data.split(',')
            // console.log(arr);
            this.form.address = arr.join(' ')
            // console.log(this.form.address, 'this.form.address');
            this.form.province = arr[0]
            this.form.city = arr[1]
            this.form.area = arr[2]

        },

        // getWechatCode() {
        //     if (isweixin()) {
        //         let appid = "wxcced3d169a1f6145"; //微信APPid
        //         let code = this.getUrlCode().code; //是否存在code
        //         let local = window.location.href;
        //         // console.log(local)
        //         if (code == null || code === "") {
        //             //不存在就打开上面的地址进行授权
        //             window.location.href =
        //                 "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        //                 appid +
        //                 "&redirect_uri=" +
        //                 encodeURIComponent(local) +
        //                 "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        //         } else {
        //             this.code = code;
        //             let data = {
        //                 code: this.code
        //             }
        //             getThisOpenid({ data }).then(res => {
        //                 console.log(res);
        //                 this.openid = res.data.info.openid
        //                 window.sessionStorage.setItem('openid', this.openid)
        //             })


        //         }
        //     }
        // },
        // getUrlCode() {
        //     // 截取url中的code方法
        //     var url = location.search;
        //     var theRequest = new Object();
        //     if (url.indexOf("?") != -1) {
        //         var str = url.substr(1);
        //         var strs = str.split("&");
        //         for (var i = 0; i < strs.length; i++) {
        //             theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        //         }
        //     }
        //     console.log(theRequest);
        //     return theRequest;
        // },

        // 购买卡片 下单
        onbuyCard() {
            // 微信环境参数
            let data = {
                goods_id: this.info.id,  //商品id
                name: this.form.name,  //收件人
                price: this.info.one_price,  //金额
                phone: this.form.phone, //手机
                invite_phone: this.invite_phone,  //邀请人手机号，没有传空
                p_openid: this.p_openid,  //邀请人openid
                openid: window.sessionStorage.getItem('openid') ? window.sessionStorage.getItem('openid') : '',
                province: this.form.province,
                city: this.form.city,
                area: this.form.area,
                address: this.form.addressInfo, //详细地址
                real_identityid: '',  //身份证
                code: this.form.cardId,  //验证码
                platform: 1,  //平台
                pay_type: 1,  //支付方式 1 微信
                from: this.from ,//来源,
            }
            // h5环境参数
            if (this.info.one_price == 0) {
                data.pay_type = 3
            }
            if (
                data.name.trim() == '') {
                return this.$toast("请填写姓名");
            }
            if (
                data.phone.trim() == '') {
                return this.$toast("请填写手机号");
            }
            if (data.address.trim() == '') {
                return this.$toast("请填写详细地址");
            }
            if (data.code.trim() == '') {
                return this.$toast("请填写验证码");
            }
            if (data.province.trim() == '' ||
                data.city.trim() == '' || data.area.trim() == '') {
                return this.$toast("请将地址填写完整");
            }

            if (
                data.province == "云南省" ||
                data.province == "广西壮族自治区" ||
                data.province == "新疆维吾尔自治区" ||
                data.province == "西藏自治区" ||
                (data.province == "广东省" && data.city == "茂名市") ||
                (data.province == "广东省" &&
                    data.city == "潮州市" &&
                    data.county == "饶平县") ||
                (data.province == "广东省" && data.city == "云浮市") ||
                (data.province == "福建省" &&
                    data.city == "泉州市" &&
                    data.county == "安溪县") ||
                (data.province == "福建省" &&
                    data.city == "漳州市" &&
                    data.county == "南靖县") ||
                (data.province == "福建省" &&
                    data.city == "龙岩市" &&
                    data.county == "新罗区") ||
                (data.province == "海南省" && data.city == "儋州市") ||
                data.province == "内蒙古自治区" ||
                data.province == "台湾省" ||
                data.province == "香港特别行政区" ||
                data.province == "澳门特别行政区" ||
                (data.province == "海南省" && data.city == "省直辖县" && data.county == "东方市")
            ) {
                return this.$toast("暂不支持此区域！");
            }
            // alert(data)
            if (this.times) {
                clearTimeout(this.times);
            }
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            this.disabled2 = true

            this.times = setTimeout(() => {
                if (isweixin()) {
                    // 微信环境
                    buyCard({ data }).then(res => {
                        // console.log(res);
                        this.disabled2 = false
                        if (this.info.one_price == 0 && res.data.code == 1) {
                            this.$toast(res.data.msg)
                        } else if (this.info.one_price != 0 && res.data.code == 1) {
                            if (isweixin()) {
                                if (typeof WeixinJSBridge == "undefined") {
                                    if (document.addEventListener) {
                                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                    } else if (document.attachEvent) {
                                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                    }
                                } else {
                                    this.onBridgeReady(res.data.info);
                                }
                            } else {
                            }
                        } else if (res.data.code != 1) {
                            this.$toast(res.data.msg)
                        }
                    })


                } else {

                    if (this.payType == 1) {
                        delete (data.openid)
                        // 微信小程序支付
                        data.platform = 4
                        data.pay_type = 1
                        console.log(data);
                        getOrderId({ data }).then(res => {

                            if (res.data.code == 1) {
                                let data = {
                                    order_id: res.data.order_id
                                }
                                // console.log(data);
                                getBuyUrlScheme({ data }).then(res => {
                                    this.disabled2 = false
                                    if (res.data.code == 1) {
                                        setTimeout(() => {

                                            window.location.href = res.data.info.urlscheme
                                        }, 1000)
                                    } else {

                                        this.$toast(res.data.msg)
                                    }
                                })



                            } else {

                                this.$toast(res.data.msg)
                            }
                            // getBuyUrlScheme
                        })
                    } else {
                        // 支付宝支付
                        data.pay_type = 5
                        buyCard({ data }).then(res => {
                            this.disabled2 = false
                            if (res.data.code == 1) {

                                console.log(res);
                                this.$toast('即将跳转支付页面')
                                setTimeout(() => {
                                    window.location.href = res.data.info
                                }, 2000);

                            } else {

                            }
                        })
                    }
                }

            }, 1000)
        },
        selectPay(num) {
            this.payType = num
        },
        // 获取本页详情
        getInfo() {
            let data = {
                sp: this.sp
            }
            getCardDetail({ data }).then(res => {
                // console.log(res);
                this.info = res.data.info
                this.getIp()
            })

        },
        getIp() {
            // console.log(this.info);
            jsonp(`https://apis.juhe.cn/ip/ipNewV3?ip=${this.info.ip}&&key=82347d8328abacbdf9e741d83476a227`, null, (err, data) => {
                if (err) {
                    console.error(err.message);
                } else {
                    // console.log(data);
                    if(data.result){
                         this.result = data.result
                         this.regionAddress = this.result.Province + ',' + this.result.City + ',' + this.result.District
                         this.getPCA()
                    }else{
                        this.result.Province = '北京'
                        this.result.City = '北京'
                        this.result.District = '东城区'
                        this.getPCA()
                    }
                    // this.form.address = this.form.province + ' ' + this.form.city + ' ' + this.form.area
                    setTimeout(() => {

                    }, 1000)

                }
            });
        },
        onBridgeReady(info) {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": info.appId, //公众号名称，由商户传入     
                "timeStamp": info.timeStamp, //时间戳，自1970年以来的秒数     
                "nonceStr": info.nonceStr, //随机串     
                "package": info.package,
                "signType": info.signType, //微信签名方式：     
                "paySign": info.paySign //微信签名 
            },
                function (res) {
                    // 支付成功
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        this.$toast('支付成功')
                    }
                    // 支付过程中用户取消
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        this.$toast('取消支付')

                    }
                    // 支付失败
                    if (res.err_msg == "get_brand_wcpay_request:fail") {
                        this.$toast('支付失败')
                    }
                    /**
                     * 其它
                     * 1、请检查预支付会话标识prepay_id是否已失效
                     * 2、请求的appid与下单接口的appid是否一致
                     * */
                    if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {

                    }
                });
        }





    },
}
</script>
<style lang="less" scoped>
.content {
    min-height: 100vh;
    background-color: #f5f5f5;

    .area {
        margin-bottom: 10px;
        display: flex;
        border-radius: 10px;
        background-color: #f7f5f5;
        height: 44px;
        // line-height: 44px;
        box-sizing: border-box;
        padding: 10px 16px;
        font-size: 14px;
        align-items: center;

        .area-a {
            -webkit-box-flex: 0;
            -webkit-flex: none;
            flex: none;
            box-sizing: border-box;
            width: 51px;
            margin-right: 0.32rem;
            color: #646566;
            text-align: left;
            word-wrap: break-word;
        }
    }

    .card1 {
        background-color: #fff;
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-bottom: 10px;
    }

    .header_pic {

        // height: 400px;
        img {
            width: 100%;
            // height: 100%;
        }
    }

    .row_price {
        display: flex;
        padding: 0 10px;
        box-sizing: border-box;
        height: 80px;
        // background-color: antiquewhite;
        justify-content: space-between;
        align-items: center;

        .price_left {
            display: flex;
            flex-direction: column;

            .price_left_top {
                width: 200px;

                // margin-bottom: 4px;
                span:nth-child(4) {
                    text-decoration: line-through;
                }
            }

            .price_left_down {
                margin-top: 10px;

                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }

                span {
                    // position: absolute;
                    display: inline;
                    width: 47%;
                    border-radius: 4px;
                    // box-sizing: border-box;
                    padding: 2px 6px;
                    background-color: #ed433d;
                    color: #fff;
                    // transform: translateY(-2px);
                }
            }
        }

        .price_right {
            width: 54px;
            // background-color: #ed433d;
            display: flex;
            align-content: center;

            .price_right_center {
                width: 100%;
                height: 54px;
                // background-color: aqua;
                margin: auto 0;
            }
        }
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 14px;
        margin-top: 5px;
    }

    .recommend {
        padding: 0 10px;
        box-sizing: border-box;

        .recommend_center {
            background-color: #f9f9f9;
            padding: 14px 10px;
            box-sizing: border-box;
            border-radius: 8px;
            color: #999999;
            line-height: 20px;
            font-size: 14px;
        }
    }

    .card2 {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #fff;
        padding: 15px 0;
        box-sizing: border-box;
        margin-bottom: 10px;
        padding-bottom: 0;

        .row1 {
            padding: 0 10px;
            box-sizing: border-box;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            height: 45px;
            line-height: 45px;
            justify-content: space-between;
            align-items: center;
        }

        .row3 {
            padding: 0 10px;
            box-sizing: border-box;
            margin-top: 17px;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            height: 45px;
            // line-height: 45px;
            justify-content: space-between;
            align-items: center;

            .top {
                -webkit-flex: none;
                flex: none;
                box-sizing: border-box;

                word-wrap: break-word;
                overflow: hidden;

                white-space: nowrap;

                text-overflow: ellipsis;
                height: 20px;

                img {
                    width: 10px;
                    height: 14px;
                    margin-right: 6px;
                }

                font-weight: 400;
            }

            .bottom {
                margin-top: 5px;
                height: 20px;


            }
        }

        .row2 {
            // padding: 0 10px;
            box-sizing: border-box;
            background-color: #fbfbfb;
            display: flex;
            justify-content: space-between;
            padding: 17px 10px;
        }

        .num {
            img {
                width: 14px;
                height: 14px;
            }

            span {
                font-size: 12px;
                color: #666666;
            }
        }
    }


    .card3 {
        box-sizing: border-box;
        padding: 17px 10px;
        background-color: #fff;

        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            .name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .red {
                width: 30px;
                height: 3px;
                background-color: red;
                border-radius: 3px;
            }
        }
    }

    .btn {
        width: 100%;
        height: 70px;
        background-color: #f9fafc;
        position: fixed;
        bottom: 0;
        padding: 0 10px 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
            display: flex;
            align-items: flex-end;
        }

        .btn-center {
            // position: absolute;
            // right: 30rpx;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
            color: white;
            border-radius: 20px;
            width: 112px;
            height: 36px;
            // margin: 0 auto;
            margin-top: 5px;
            background-image: -moz-linear-gradient(0deg, rgb(253, 100, 79) 0%, rgba(242, 142, 38, 0.99608) 100%);
            background-image: -webkit-linear-gradient(0deg, rgb(253, 100, 79) 0%, rgba(242, 142, 38, 0.99608) 100%);
            background-image: -ms-linear-gradient(0deg, rgb(253, 100, 79) 0%, rgba(242, 142, 38, 0.99608) 100%);
            box-shadow: 0 1px 1px 1px gainsboro;
        }

        .btn-center:active {
            opacity: 0.7;
        }


    }

    .popBox {
        width: 100%;
        padding: 10px;
        padding-top: 40px;
        box-sizing: border-box;
        // background-color: #999999;
        height: 100%;

        .inp {
            margin-bottom: 10px;
        }

        .payType {
            box-sizing: border-box;
            position: relative;
            width: 100%;
            background-color: #f7f5f5;
            border-radius: 10px;
            padding: 0 10px;
            box-sizing: border-box;

            .item {
                height: 54px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                    }
                }

                .right {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

            }

            // .item:nth-child(1) {
            //     // border-bottom: 1px solid #e6e6e6;
            // }



        }

        /deep/ .van-cell-group {
            background-color: #f7f5f5;
            border-radius: 10px;

            .van-cell__title {
                width: 50px;
            }

            .van-cell {
                background-color: #f7f5f5;
                border-radius: 10px;
            }

            /deep/ .van-cell::after {
                border: none !important;
            }

            .van-hairline--top-bottom::after {
                border: none;
            }
        }

        .footbtn {
            // position: fixed;
            // bottom: 0;
            margin-top: 40px;
            width: 355px;
            height: 70px;
            // background-color: #ed433d;
            box-sizing: border-box;

        }

    }

    .important {
        margin: 10px 0;
        color: #e58100;
    }
}
</style>