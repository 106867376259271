<template>
 <div class="Button3">
        <div class="button" @click="visible = true">{{address}}</div>
        <PickerRegion
            :visible.sync="visible"
            :defaultRegion="region"
            title="请选择地址"
            cancelText="取消"
            confirmText=" 确定"
            :maskClick="false"
            @cancel="cancel"
            @confirm="confirm"
        />
    </div>
   
</template>


<script>
import 'vue-min-picker/lib/picker.css';//导入样式(建议全局导入)
import pickers from "vue-min-picker";
let {PickerRegion} = pickers;
export default {
	components:{
		PickerRegion
	},
    props:{
        address:{
            type:String,
            default:'请选择收获地址'
        },
        region:{
            type:String,
            default:''
        }
    },
	name: "Button3",
    data() {
        return {
            visible:false,
            defaultRegion:"",
        };
    },
    mounted() {
        this.$nextTick(function() {
            this.defaultRegion = this.region;
            console.log( this.region);
        });
    },
    methods: {
        confirm(e){
            console.log(e);
            console.log('确定: ', JSON.stringify(e));
            this.$emit('sendCity',e)

        },
        cancel(){
            console.log("取消了");
        }
    },
};
</script>
<style>

</style>